import React from "react";

function GlacialWays() {
  return (
    <div className="glacial">
      <div className="glac_text">
        <h3>For the password email: </h3>
        <h3>maxmillstein1@gmail.com</h3>
      </div>
      <label>Password: </label>
      <input></input>
    </div>
  );
}

export default GlacialWays;
